"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isActiveTab = exports.scrollToBlock = void 0;
const react_scroll_1 = require("react-scroll");
// Фунция скрола до блока
function scrollToBlock(block) {
    react_scroll_1.scroller.scrollTo(block, {
        duration: 500,
        delay: 0,
        offset: window.innerWidth <= 576 ? -60 : -100,
        smooth: true,
        ignoreCancelEvents: true
    });
}
exports.scrollToBlock = scrollToBlock;
// Функция определяющая является ли пункт меню выбранным
function isActiveTab(activeTab, tab) {
    return activeTab === tab;
}
exports.isActiveTab = isActiveTab;
