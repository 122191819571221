"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
// Хук определения нахождения элемента в области видимости документа (viewport)
// Принимает реф элемента, за которым устанавливается слежение и отступ для установления изменения стейта
function useIntersection(element, rootMargin) {
    const [isVisible, setIsVisible] = (0, react_1.useState)(false);
    let timer = null;
    // Подписка на наблюдение за элементом
    (0, react_1.useEffect)(() => {
        // При изменении позиции видимости элемента больше или меньше указанного отступа изменяется стейт
        const observer = new IntersectionObserver(([entry]) => {
            clearTimeout(timer);
            timer = setTimeout(() => setIsVisible(entry.isIntersecting), 250);
        }, { rootMargin });
        element?.current && observer.observe(element.current);
        return () => element?.current && observer.unobserve(element.current);
    }, []);
    return isVisible;
}
exports.default = useIntersection;
