"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
// Хук определения устройства и ресайза интерфейса под соответствующее разрешение
function useDeviceDetect() {
    const [isMobile, setIsMobile] = (0, react_1.useState)(window.innerWidth <= 769);
    const [isTablet, setIsTablet] = (0, react_1.useState)(window.innerWidth <= 1100);
    const [isPhone, setIsPhone] = (0, react_1.useState)(window.innerWidth <= 500);
    (0, react_1.useEffect)(() => {
        const handlePageResized = () => {
            setIsMobile(window.innerWidth <= 769);
            setIsTablet(window.innerWidth <= 1100);
            setIsPhone(window.innerWidth <= 500);
        };
        window.addEventListener('resize', handlePageResized);
        window.addEventListener('orientationchange', handlePageResized);
        window.addEventListener('load', handlePageResized);
        window.addEventListener('reload', handlePageResized);
        return () => {
            window.removeEventListener('resize', handlePageResized);
            window.removeEventListener('orientationchange', handlePageResized);
            window.removeEventListener('load', handlePageResized);
            window.removeEventListener('reload', handlePageResized);
        };
    }, []);
    return { isMobile, isTablet, isPhone };
}
exports.default = useDeviceDetect;
